import CloseIcon from "@mui/icons-material/Close";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SaveIcon from "@mui/icons-material/Save";
import { AppBar, Box, Tab, Tabs } from "@mui/material";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { green } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as activityApi from "../../api/activityApi";
import * as applicationApi from "../../api/applicationApi";
import * as rateApi from "../../api/rateApi";
import { dateFormatter } from "../../common/dateFormatter";
import { sortAlphabetically } from "../../common/sortAlphabetically";
import { stateList } from "../../common/stateList.js";
import { timeFormatter } from "../../common/timeFormatter";
import * as terminalActions from "../../redux/actions/terminalActions";
import "../../styles/truck.css";
import ActivityLogGrid from "../Activity/ActivityLogGrid";
import DraftJsEditor from "../DraftJsEditor/DraftJsEditor";
import ExpireRatesDialog from "../ExpireRatesDialog/ExpireRatesDialog";
import ExtendRatesDialog from "../ExtendRatesDialog/ExtendRatesDialog";
import FileUpload from "../FileUpload/FileUpload";
import containerLogo from "../Logo/ContainerPort-Logo-white.png";
import TerminalGroup from "./TerminalGroup";

const TerminalDetails = (props) => {
   var history = useHistory();
   var handleError = useErrorHandler();

   let [selectedTerminal, setSelectedTerminal] = useState(null);
   let [termGroupList, setTermGroupList] = useState([]);
   let [loading, setLoading] = useState(true);
   let [loadingGroup, setLoadingGroup] = useState(true);
   let [readOnly, setReadOnly] = useState(false);
   let [openDrawer, setOpenDrawer] = useState(false);
   let [showAlert, setShowAlert] = useState(false);
   let [alertMessage, setAlertMessage] = useState(null);
   let [alertType, setAlertType] = useState("");
   let [loadActivity, setLoadActivity] = useState(true);
   let [activityModal, setActivityModal] = useState(false);
   let [exportingRates, setExportingRates] = useState(false);
   const [tabsValue, setTabsValue] = useState(0);
   const [activityTableData, setActivityTableData] = useState([]);
   const [extendButtonDisabled, setExtendButtonDisabled] = useState(true);
   const [extendDate, setExtendDate] = useState(null);
   const [extendRatesModal, setExtendRatesModal] = useState(false);
   const [expireButtonDisabled, setExpireButtonDisabled] = useState(true);
   const [expireDate, setExpireDate] = useState(null);
   const [expireRatesModal, setExpireRatesModal] = useState(false);

   useEffect(() => {
      //retrieve record from API
      applicationApi.getTerminals(props.match.params.id).then((resp) => {
         if (resp.terminals.length > 0) {
            var newSelectedTerminal = resp.terminals[0];
            setSelectedTerminal(newSelectedTerminal);
            loadGroupTerms(newSelectedTerminal);
            setLoading(false);
         }
      });
   }, []);

   //load in security
   useEffect(() => {
      var curUserRoute = props.user.security.routes.filter((rt) => rt.module === "termmanagement");
      var userReadOnly = true;
      if (!props.user.security.admin) {
         switch (curUserRoute[0].permission) {
            case "readWrite":
               userReadOnly = false;
               break;
            case "readOnly":
               userReadOnly = true;
               break;
         }
      } else {
         userReadOnly = false;
      }

      setReadOnly(userReadOnly);
   }, [props.user]);

   useEffect(() => {
      activityApi
         .getActivity(null, null, null, [
            {
               module: "TERMINAL",
               record_id: props.match.params.id,
               rows: null,
            },
         ])
         .then((resp) => {
            //Build the tableData
            const newTableData = [];

            resp.activities.forEach((activityObjectResp) => {
               activityObjectResp.activity.forEach((activity, i) => {
                  const newActivityObj = {};

                  newActivityObj["_id"] = activityObjectResp._id;
                  newActivityObj["id"] = `${activityObjectResp._id}-${i}`;
                  newActivityObj["date"] = dateFormatter(activityObjectResp.add_date);
                  newActivityObj["hour"] = timeFormatter(activityObjectResp.add_date);
                  newActivityObj["event"] = activity.event_code || "N/A";
                  newActivityObj["description"] = activity.event || "N/A";

                  newTableData.push(newActivityObj);
               });
            });

            setActivityTableData(newTableData);
         })
         .catch((err) => {
            console.error(err);
         });
   }, [loadActivity]);

   useEffect(() => {
      checkExtendButtonSecurity();
   }, [extendDate]);

   useEffect(() => {
      checkExpireButtonSecurity();
   }, [expireDate]);

   function loadGroupTerms(pterm) {
      try {
         setLoadingGroup(true);
         var newTermGroupList = [];

         //set group terminals for terminal
         props.terminals.forEach((term) => {
            //skip if its a group term
            var updateTerm = _.clone(term);
            if (updateTerm.group.isGroup === false) {
               const filteredData = pterm.group.terminal_ids.filter((termID) => {
                  return termID.match(updateTerm._id);
               });

               if (filteredData <= 0) {
                  newTermGroupList.push(updateTerm);
               }
            }
         });

         setTermGroupList(newTermGroupList);
         setLoadingGroup(false);
      } catch (err) {
         handleError(err);
      }
   }

   function onChange(event) {
      try {
         var newSelectedTerminal = _.cloneDeep(selectedTerminal);

         //check for specific objects to save into
         if (event.target.name === "isGroup") {
            newSelectedTerminal.group[event.target.name] =
               event.target.type === "checkbox" ? event.target.checked : event.target.value;
         } else {
            newSelectedTerminal[event.target.name] =
               event.target.type === "checkbox" ? event.target.checked : event.target.value;
         }

         setSelectedTerminal(newSelectedTerminal);
      } catch (err) {
         handleError(err);
      }
   }

   function onChangeAddress(event) {
      try {
         var newSelectedTerminal = _.cloneDeep(selectedTerminal);
         newSelectedTerminal.address[event.target.name] = event.target.value;
         setSelectedTerminal(newSelectedTerminal);
      } catch (err) {
         handleError(err);
      }
   }

   function onChangeRateSheet(event) {
      try {
         var newSelectedTerminal = _.cloneDeep(selectedTerminal);
         newSelectedTerminal.rateSheet[event.target.name] = event.target.value;
         setSelectedTerminal(newSelectedTerminal);
      } catch (err) {
         handleError(err);
      }
   }

   function onChangeEditorDisclaimer(field, newValue) {
      try {
         const newSelectedTerminal = _.cloneDeep(selectedTerminal);
         newSelectedTerminal.rateSheet.disclaimer = newValue;
         setSelectedTerminal(newSelectedTerminal);
      } catch (err) {
         handleError(err);
      }
   }

   function onChangeEditorSurcharge(field, newValue) {
      try {
         const newSelectedTerminal = _.cloneDeep(selectedTerminal);
         newSelectedTerminal.rateSheet.surcharge = newValue;
         setSelectedTerminal(newSelectedTerminal);
      } catch (err) {
         handleError(err);
      }
   }

   function updateTerm() {
      //Validate Name and Code non-empty
      if (selectedTerminal.name.length === 0 || selectedTerminal.code.length === 0) {
         return;
      }
      applicationApi
         .saveTerminal(selectedTerminal)
         .then((resp) => {
            //Display message acording to resp sucess
            if (!resp.success) {
               openAlertMessage(resp.message, "error");
            } else {
               openAlertMessage("Terminal Successfully Updated.", "success");
            }

            //update original record in termlist
            props.getTerminals();

            //Refresh activity
            setLoadActivity(true);
         })
         .catch((err) => {
            openAlertMessage(`Error saving terminal: ${err}`, "error");
         });
   }

   async function exportRates() {
      setExportingRates(true);

      await rateApi
         .exportRates(props.match.params.id, null, props.user._id, null, 'Terminal Rate')
         .then(() => {
            //success
            openAlertMessage(
               "Terminal Rate Export has started. You will receive a notification and email once it has completed",
               "success",
            );

            setExportingRates(false);

            //Refresh activity
            setLoadActivity(true);
         })
         .catch((err) => {
            openAlertMessage(`${err}`, "error");
            setExportingRates(false);

            //Refresh activity
            setLoadActivity(true);
         });
   }

   const useStyles = makeStyles((theme) => ({
      secondaryHeader: {
         borderBottom: "1px solid #2F3136",
         zIndex: "3",
         width: "100%",
      },
      layoutMain: {
         marginTop: "2rem",
         position: "fixed",
         width: "inherit",
         height: "100%",
      },
      layoutBody: {
         position: "absolute",
         height: "calc(100% - 9.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      layoutBody2: {
         position: "absolute",
         height: "calc(100% - 12.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      search: {
         position: "relative",
         borderRadius: theme.shape.borderRadius,
         border: "1px solid black",
         backgroundColor: "#ffff",
         marginRight: theme.spacing(2),
         marginLeft: 0,
         width: "30%",
         float: "right",
         [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
         },
      },
      searchIcon: {
         color: "black",
         padding: theme.spacing(0, 2),
         height: "100%",
         position: "absolute",
         pointerEvents: "none",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
      save: {
         float: "right",
         fontSize: "2rem",
         marginRight: ".25rem",
      },
      appBar: {
         "& .MuiButtonBase-root": {
            padding: "1.25rem 2.25rem",
         },
         "& .MuiTabs-indicator": {
            height: "4px",
         },
      },
      back: {
         color: "white",
         backgroundColor: "#2F3136",
         float: "right",
         marginRight: "1rem",
         "&:hover": {
            backgroundColor: "black",
         },
      },
      inputRoot: {},
      inputInput: {
         padding: theme.spacing(1, 1, 1, 0),
         paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
         transition: theme.transitions.create("width"),
         width: "100%",
         [theme.breakpoints.up("md")]: {
            width: "20ch",
         },
      },
      listDrawer: {
         width: "300px",
         maxWidth: 360,
         backgroundColor: theme.palette.background.paper,
      },
      buttonProgress: {
         color: green[500],
      },
   }));

   const classes = useStyles();

   function openAlertMessage(alertMessage, alertType) {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            closeAlertMessage();
         }, 3000);
      }
   }

   const handleTabsChange = (event, newValue) => {
      setTabsValue(newValue);
   };

   function a11yProps(index) {
      return {
         id: `simple-tab-${index}`,
         "aria-controls": `simple-tabpanel-${index}`,
      };
   }

   function closeAlertMessage() {
      setShowAlert(false);
   }

   function breadcrumbOnClick(path) {
      if (path) {
         history.push(path);
      }
   }

   function checkExtendButtonSecurity() {
      if (!props.user) {
         setExtendButtonDisabled(true);
         return;
      }

      if (!props.user.security.admin) {
         setExtendButtonDisabled(true);
         return;
      }

      if (!extendDate) {
         setExtendButtonDisabled(true);
         return;
      }

      if (!selectedTerminal) {
         setExtendButtonDisabled(true);
         return;
      }

      if (selectedTerminal.group.isGroup) {
         setExtendButtonDisabled(true);
         return;
      }

      var d = new Date();
      d.setHours(0, 0, 0, 0);

      var extendDateFormatted = new Date(extendDate);

      var extendDateUtc = new Date(
         extendDateFormatted.getUTCFullYear(),
         extendDateFormatted.getUTCMonth(),
         extendDateFormatted.getUTCDate(),
         extendDateFormatted.getUTCHours(),
         extendDateFormatted.getUTCMinutes(),
         extendDateFormatted.getUTCSeconds(),
      );

      if (extendDateUtc.getTime() < d.getTime()) {
         setExtendButtonDisabled(true);
         return;
      }

      setExtendButtonDisabled(false);
   }

   function checkExpireButtonSecurity() {
      if (!props.user) {
         setExpireButtonDisabled(true);
         return;
      }

      if (!props.user.security.admin) {
         setExpireButtonDisabled(true);
         return;
      }

      if (!expireDate) {
         setExpireButtonDisabled(true);
         return;
      }

      if (!selectedTerminal) {
         setExpireButtonDisabled(true);
         return;
      }

      if (selectedTerminal.group.isGroup) {
         setExpireButtonDisabled(true);
         return;
      }

      var d = new Date();
      d.setHours(0, 0, 0, 0);

      var expireDateFormatted = new Date(expireDate);

      var expireDateUtc = new Date(
         expireDateFormatted.getUTCFullYear(),
         expireDateFormatted.getUTCMonth(),
         expireDateFormatted.getUTCDate(),
         expireDateFormatted.getUTCHours(),
         expireDateFormatted.getUTCMinutes(),
         expireDateFormatted.getUTCSeconds(),
      );

      if (expireDateUtc.getTime() >= d.getTime()) {
         setExpireButtonDisabled(true);
         return;
      }

      setExpireButtonDisabled(false);
   }

   function extendRates() {
      setExtendRatesModal(!extendRatesModal);

      rateApi
         .extendRates(props.match.params.id, "", props.user._id, extendDate)
         .then((resp) => {
            openAlertMessage(
               "Terminal Rate Extension has started. You will receive a notification and email once it has completed",
               "success",
            );
         })
         .catch((err) => openAlertMessage(`Error extending rates: ${err}`, "error"));
   }

   function expireRates() {
      setExpireRatesModal(!expireRatesModal);

      rateApi
         .expireRates(props.match.params.id, "", props.user._id, expireDate)
         .then((resp) => {
            openAlertMessage(
               "Terminal Rate Expiration has started. You will receive a notification and email once it has completed",
               "success",
            );
         })
         .catch((err) => openAlertMessage(`Error expiring rates: ${err}`, "error"));
   }

   return (
      <>
         {loading ? (
            <div className="loader-wrapper">
               <div className="truck-wrapper">
                  <div className="truck">
                     <div className="truck-container">
                        <img style={{ width: "65px", paddingLeft: "5px", paddingTop: "15px" }} src={containerLogo} />
                     </div>
                     <div className="glases"></div>
                     <div className="bonet"></div>
                     <div className="base"></div>
                     <div className="base-aux"></div>
                     <div className="wheel-back"></div>
                     <div className="wheel-front"></div>
                     <div className="smoke"></div>
                  </div>
               </div>
            </div>
         ) : (
            <div id={"TerminalDetails"} className={classes.layoutMain}>
               <div className={classes.secondaryHeader}>
                  <Grid container direction="rows" justifyContent="space-between" alignItems="center">
                     <Grid item xs={6}>
                        {!loading ? (
                           <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                              <Link
                                 color="inherit"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => breadcrumbOnClick("/terminal")}
                              >
                                 <h3 style={{ fontWeight: "500" }}>{"Terminals"}</h3>
                              </Link>
                              <Link
                                 color="inherit"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => breadcrumbOnClick("/terminal/details/" + props.match.params.id)}
                              >
                                 <h3 style={{ fontWeight: "500" }}>
                                    {selectedTerminal.name ? selectedTerminal.name : null}
                                 </h3>
                              </Link>
                           </Breadcrumbs>
                        ) : null}
                     </Grid>
                     <Grid item xs={6}>
                        <div>
                           <Tooltip title="Export Terminal Rates" arrow>
                              <IconButton
                                 style={{ marginRight: "1rem", float: "right" }}
                                 variant="contained"
                                 color="primary"
                                 onClick={exportRates}
                                 disabled={props.user.security.admin && !selectedTerminal.group.isGroup ? false : true}
                              >
                                 {exportingRates ? (
                                    <CircularProgress size={32} className={classes.buttonProgress} />
                                 ) : (
                                    <ImportExportIcon style={{ fontSize: "2rem" }} />
                                 )}
                              </IconButton>
                           </Tooltip>
                           <Tooltip title="Save" arrow>
                              <IconButton
                                 className={classes.save}
                                 variant="contained"
                                 color="primary"
                                 onClick={() => updateTerm()}
                                 disabled={readOnly}
                              >
                                 <SaveIcon style={{ fontSize: "2rem" }} />
                              </IconButton>
                           </Tooltip>
                        </div>
                     </Grid>
                  </Grid>
                  <Collapse in={showAlert}>
                     <Alert
                        style={{ color: "#FFFFFF" }}
                        variant="filled"
                        severity={alertType ? alertType : "success"}
                        action={
                           <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                 {
                                    closeAlertMessage();
                                 }
                              }}
                           >
                              <CloseIcon fontSize="inherit" />
                           </IconButton>
                        }
                     >
                        {alertMessage}
                     </Alert>
                  </Collapse>
               </div>
               <div className={showAlert === true ? classes.layoutBody2 : classes.layoutBody}>
                  <Grid container direction="row" justifyContent="space-between">
                     <Grid item xs={12}>
                        <Grid container direction="column" justifyContent="space-between" alignItems="center">
                           <Grid item sx={{ width: "100%" }}>
                              <Box p="1%" width="100%">
                                 <Paper elevation={3}>
                                    <AppBar
                                       position="static"
                                       color="default"
                                       className={classes.appBar}
                                       sx={{
                                          boxShadow: "none",
                                          borderBottom: "1px solid black",
                                       }}
                                    >
                                       <Tabs
                                          indicatorColor="primary"
                                          textColor="primary"
                                          onChange={handleTabsChange}
                                          value={tabsValue}
                                          aria-label="tabs"
                                       >
                                          <Tab
                                             sx={{
                                                fontSize: "1rem",
                                                fontWeight: "700",
                                                letterSpacing: "0px",
                                                textTransform: "none",
                                             }}
                                             label="Terminal Details"
                                             {...a11yProps(0)}
                                          />
                                          <Tab
                                             sx={{
                                                fontSize: "1rem",
                                                fontWeight: "700",
                                                letterSpacing: "0px",
                                                textTransform: "none",
                                             }}
                                             label="Activity"
                                             {...a11yProps(1)}
                                          />
                                       </Tabs>
                                    </AppBar>
                                    {tabsValue === 0 && (
                                       <Box sx={{ padding: "0 2rem 1rem 0" }}>
                                          <Grid container direction={"row"} justifyContent="space-between">
                                             <Grid item xs={6}>
                                                <Grid
                                                   container
                                                   direction={"column"}
                                                   justifyContent="space-between"
                                                   alignItems="center"
                                                >
                                                   <Grid item style={{ width: "100%" }}>
                                                      <div style={{ padding: "1%", width: "100%" }}>
                                                         <Paper elevation={3} style={{ padding: "3%" }}>
                                                            <Grid
                                                               container
                                                               direction={"column"}
                                                               justifyContent="space-between"
                                                            >
                                                               <Grid item></Grid>
                                                               <Grid item style={{ width: "100%", padding: "1%" }}>
                                                                  <div>
                                                                     <TextField
                                                                        error={
                                                                           selectedTerminal.name.length > 0
                                                                              ? false
                                                                              : true
                                                                        }
                                                                        style={{ marginRight: "1rem" }}
                                                                        id={
                                                                           selectedTerminal.name.length > 0
                                                                              ? "tf-code"
                                                                              : "outlined-error-helper-text"
                                                                        }
                                                                        label="Name"
                                                                        helperText={
                                                                           selectedTerminal.name.length === 0 &&
                                                                           "Required Field"
                                                                        }
                                                                        variant="standard"
                                                                        name="name"
                                                                        value={selectedTerminal.name}
                                                                        onChange={(e) => onChange(e)}
                                                                        disabled={readOnly}
                                                                        required
                                                                     />
                                                                     <TextField
                                                                        error={
                                                                           selectedTerminal.code.length > 0
                                                                              ? false
                                                                              : true
                                                                        }
                                                                        style={{ marginRight: "1rem" }}
                                                                        id={
                                                                           selectedTerminal.code.length > 0
                                                                              ? "tf-code"
                                                                              : "outlined-error-helper-text"
                                                                        }
                                                                        label="Code"
                                                                        helperText={
                                                                           selectedTerminal.code.length === 0 &&
                                                                           "Required Field"
                                                                        }
                                                                        variant="standard"
                                                                        name="code"
                                                                        value={selectedTerminal.code}
                                                                        onChange={(e) => onChange(e)}
                                                                        disabled={readOnly}
                                                                     />
                                                                     <TextField
                                                                        style={{ marginRight: "1rem" }}
                                                                        id="tf-address1"
                                                                        label="Address 1"
                                                                        variant="standard"
                                                                        name="address1"
                                                                        value={selectedTerminal.address.address1}
                                                                        onChange={(e) => onChangeAddress(e)}
                                                                        disabled={readOnly}
                                                                     />
                                                                     <TextField
                                                                        style={{ marginRight: "1rem" }}
                                                                        id="tf-address2"
                                                                        label="Address 2"
                                                                        variant="standard"
                                                                        name="address2"
                                                                        value={selectedTerminal.address.address2}
                                                                        onChange={(e) => onChangeAddress(e)}
                                                                        disabled={readOnly}
                                                                     />
                                                                     <TextField
                                                                        style={{ marginRight: "1rem" }}
                                                                        id="tf-city"
                                                                        label="City"
                                                                        variant="standard"
                                                                        name="city"
                                                                        value={selectedTerminal.address.city}
                                                                        onChange={(e) => onChangeAddress(e)}
                                                                        disabled={readOnly}
                                                                     />
                                                                     <FormControl
                                                                        style={{ width: "5rem" }}
                                                                        variant="standard"
                                                                     >
                                                                        <InputLabel id="state-simple-select-label">
                                                                           State
                                                                        </InputLabel>
                                                                        <Select
                                                                           labelId="state-simple-select-label"
                                                                           id="state-simple-select"
                                                                           value={selectedTerminal.address.state}
                                                                           name="state"
                                                                           onChange={(e) => onChangeAddress(e)}
                                                                           disabled={readOnly}
                                                                           style={{ marginRight: "1rem" }}
                                                                        >
                                                                           <MenuItem value={null}>Choose...</MenuItem>
                                                                           {stateList.map((state, index) => {
                                                                              return (
                                                                                 <MenuItem
                                                                                    key={`mi-${index}`}
                                                                                    value={state}
                                                                                 >
                                                                                    {state}
                                                                                 </MenuItem>
                                                                              );
                                                                           })}
                                                                        </Select>
                                                                     </FormControl>
                                                                     <TextField
                                                                        style={{ marginRight: "1rem", width: "5rem" }}
                                                                        id="tf-zip"
                                                                        label="Zip"
                                                                        variant="standard"
                                                                        name="zip"
                                                                        value={selectedTerminal.address.zip}
                                                                        onChange={(e) => onChangeAddress(e)}
                                                                        disabled={readOnly}
                                                                     />
                                                                  </div>
                                                                  <Grid item style={{ paddingTop: "1rem" }}>
                                                                     <FormControlLabel
                                                                        style={{ float: "left" }}
                                                                        control={
                                                                           <Switch
                                                                              name="active"
                                                                              checked={selectedTerminal.active}
                                                                              onClick={onChange}
                                                                              color="primary"
                                                                              disabled={readOnly}
                                                                           />
                                                                        }
                                                                        label="Active"
                                                                     />
                                                                     <FormControlLabel
                                                                        style={{ float: "left" }}
                                                                        control={
                                                                           <Switch
                                                                              name="isGroup"
                                                                              checked={selectedTerminal.group.isGroup}
                                                                              onClick={onChange}
                                                                              color="primary"
                                                                              disabled={readOnly}
                                                                           />
                                                                        }
                                                                        label="Is Group Terminal"
                                                                     />
                                                                  </Grid>
                                                                  <Grid item>
                                                                     <InputLabel
                                                                        id="il-fuel-tariff"
                                                                        style={{
                                                                           fontSize: "smaller",
                                                                           inlineSize: "fit-content",
                                                                           marginTop: "4rem",
                                                                        }}
                                                                     >
                                                                        Fuel Tariff
                                                                     </InputLabel>
                                                                     <Select
                                                                        name="fuelTariff_id"
                                                                        value={selectedTerminal.fuelTariff_id}
                                                                        onChange={onChange}
                                                                        style={{ width: "10rem" }}
                                                                        disabled={readOnly}
                                                                        variant="standard"
                                                                     >
                                                                        {sortAlphabetically(props.fuelTariffs).map(
                                                                           (tar, index) => {
                                                                              return (
                                                                                 <MenuItem
                                                                                    key={`mi-fuelTariffs-${index}`}
                                                                                    value={tar._id}
                                                                                 >
                                                                                    {tar.name}
                                                                                 </MenuItem>
                                                                              );
                                                                           },
                                                                        )}
                                                                     </Select>
                                                                     <InputLabel
                                                                        id="il-accessorial-tariff"
                                                                        style={{
                                                                           fontSize: "smaller",
                                                                           paddingTop: "1rem",
                                                                        }}
                                                                     >
                                                                        Accessorial Tariff
                                                                     </InputLabel>
                                                                     <Select
                                                                        name="accessorialTariff_id"
                                                                        value={selectedTerminal.accessorialTariff_id}
                                                                        onChange={onChange}
                                                                        style={{ width: "10rem" }}
                                                                        disabled={readOnly}
                                                                        variant="standard"
                                                                     >
                                                                        {sortAlphabetically(
                                                                           props.accessorialTariffs,
                                                                        ).map((tar, index) => {
                                                                           return (
                                                                              <MenuItem
                                                                                 key={`mi-accessorialTariffs-${index}`}
                                                                                 value={tar._id}
                                                                              >
                                                                                 {tar.name}
                                                                              </MenuItem>
                                                                           );
                                                                        })}
                                                                     </Select>
                                                                  </Grid>
                                                               </Grid>
                                                            </Grid>
                                                         </Paper>
                                                      </div>
                                                   </Grid>
                                                   <Grid item style={{ width: "100%" }}>
                                                      <div style={{ padding: "1%", width: "100%" }}>
                                                         <Paper elevation={3} style={{ padding: "3%" }}>
                                                            <FileUpload
                                                               directory="terminals"
                                                               share="rates"
                                                               termID={selectedTerminal._id}
                                                               custID=""
                                                               userID={props.user._id}
                                                               openAlertMessage={openAlertMessage}
                                                            />
                                                         </Paper>
                                                      </div>
                                                   </Grid>
                                                   <Grid
                                                      container
                                                      style={{ width: "100%", display: readOnly ? "none" : null }}
                                                      justifyContent="space-between"
                                                      alignItems="center"
                                                   >
                                                      <div style={{ padding: "1%", width: "100%" }}>
                                                         <Paper
                                                            style={{
                                                               padding: "3%",
                                                               height: "fit-content",
                                                               width: "100%",
                                                            }}
                                                         >
                                                            <h5>Extend/Expire Terminal Rates</h5>
                                                            <TextField
                                                               id="extendDate"
                                                               label="Extend Rates to Date"
                                                               type="date"
                                                               value={extendDate}
                                                               onChange={(event) =>
                                                                  setExtendDate(
                                                                     event.target.value ? event.target.value : null,
                                                                  )
                                                               }
                                                               InputLabelProps={{
                                                                  shrink: true,
                                                               }}
                                                               variant="outlined"
                                                               style={{ marginRight: "1rem" }}
                                                               disabled={
                                                                  !props.user ||
                                                                  !props.user.security.admin ||
                                                                  !selectedTerminal ||
                                                                  selectedTerminal.group.isGroup
                                                               }
                                                            />
                                                            <Button
                                                               sx={{
                                                                  display: "inline-flex",
                                                                  flexDirection: "column",
                                                                  alignItems: "center",
                                                                  fontSize: "11px",
                                                                  height: "55px",
                                                                  lineHeight: "13px",
                                                                  marginRight: "1rem",
                                                               }}
                                                               variant="contained"
                                                               color="primary"
                                                               onClick={() => setExtendRatesModal(true)}
                                                               disabled={extendButtonDisabled}
                                                            >
                                                               <DateRangeIcon sx={{ marginBottom: "0.1rem" }} />
                                                               Extend Rates
                                                            </Button>
                                                            <TextField
                                                               id="expireDate"
                                                               label="Expire Rates on Date"
                                                               type="date"
                                                               value={expireDate}
                                                               onChange={(event) =>
                                                                  setExpireDate(
                                                                     event.target.value ? event.target.value : null,
                                                                  )
                                                               }
                                                               InputLabelProps={{
                                                                  shrink: true,
                                                               }}
                                                               variant="outlined"
                                                               style={{ marginRight: "1rem" }}
                                                               disabled={
                                                                  !props.user ||
                                                                  !props.user.security.admin ||
                                                                  !selectedTerminal ||
                                                                  selectedTerminal.group.isGroup
                                                               }
                                                            />
                                                            <Button
                                                               sx={{
                                                                  display: "inline-flex",
                                                                  flexDirection: "column",
                                                                  alignItems: "center",
                                                                  fontSize: "11px",
                                                                  height: "55px",
                                                                  lineHeight: "13px",
                                                               }}
                                                               variant="contained"
                                                               color="primary"
                                                               onClick={() => setExpireRatesModal(true)}
                                                               disabled={expireButtonDisabled}
                                                            >
                                                               <DateRangeIcon sx={{ marginBottom: "0.1rem" }} />
                                                               Expire Rates
                                                            </Button>
                                                         </Paper>
                                                      </div>
                                                   </Grid>
                                                   <Grid item style={{ width: "100%" }}>
                                                      <div style={{ padding: "1%", width: "100%" }}>
                                                         <Paper
                                                            elevation={3}
                                                            style={{ padding: "3%", display: "grid" }}
                                                         >
                                                            <h3>Rate Sheet</h3>
                                                            <TextField
                                                               style={{ margin: "3%" }}
                                                               id="tf-title"
                                                               label="Title"
                                                               name="title"
                                                               onChange={(e) => onChangeRateSheet(e)}
                                                               value={selectedTerminal.rateSheet.title}
                                                               disabled={readOnly}
                                                            />
                                                            <TextField
                                                               style={{ marginTop: "2rem" }}
                                                               id="tf-railDescription"
                                                               label="Rail Description"
                                                               name="railDescription"
                                                               multiline
                                                               rows={4}
                                                               onChange={(e) => onChangeRateSheet(e)}
                                                               value={selectedTerminal.rateSheet.railDescription}
                                                               variant="outlined"
                                                               disabled={readOnly}
                                                            />
                                                            <div style={{ marginTop: "2rem" }}>
                                                               <DraftJsEditor
                                                                  id="dje-disclaimer"
                                                                  label="Disclaimer"
                                                                  name="disclaimer"
                                                                  state={selectedTerminal.rateSheet.disclaimer}
                                                                  onChangeEditor={onChangeEditorDisclaimer}
                                                               />
                                                            </div>
                                                            <div style={{ marginTop: "2rem" }}>
                                                               <DraftJsEditor
                                                                  id="dje-surcharge"
                                                                  label="Terminal Specific Accessorials"
                                                                  name="surcharge"
                                                                  state={selectedTerminal.rateSheet.surcharge}
                                                                  onChangeEditor={onChangeEditorSurcharge}
                                                               />
                                                            </div>
                                                            <TextField
                                                               style={{ margin: "3%" }}
                                                               id="tf-email"
                                                               label="Email"
                                                               name="email"
                                                               onChange={(e) => onChangeRateSheet(e)}
                                                               value={selectedTerminal.rateSheet.email}
                                                               disabled={readOnly}
                                                            />
                                                            <TextField
                                                               style={{ margin: "3%" }}
                                                               id="tf-phone"
                                                               label="Phone #"
                                                               name="phoneNumber"
                                                               onChange={(e) => onChangeRateSheet(e)}
                                                               value={selectedTerminal.rateSheet.phoneNumber}
                                                               disabled={readOnly}
                                                            />
                                                         </Paper>
                                                      </div>
                                                   </Grid>
                                                </Grid>
                                             </Grid>
                                             <Grid item xs={6}>
                                                {loadingGroup ? (
                                                   <div className="loader-wrapper">
                                                      <div className="truck-wrapper">
                                                         <div className="truck">
                                                            <div className="truck-container">
                                                               <img
                                                                  style={{
                                                                     width: "65px",
                                                                     paddingLeft: "5px",
                                                                     paddingTop: "15px",
                                                                  }}
                                                                  src={containerLogo}
                                                               />
                                                            </div>
                                                            <div className="glases"></div>
                                                            <div className="bonet"></div>
                                                            <div className="base"></div>
                                                            <div className="base-aux"></div>
                                                            <div className="wheel-back"></div>
                                                            <div className="wheel-front"></div>
                                                            <div className="smoke"></div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                ) : (
                                                   <div
                                                      style={
                                                         !selectedTerminal.group.isGroup
                                                            ? {
                                                                 cursor: "not-allowed",
                                                                 pointerEvents: "none",
                                                                 opacity: "0.6",
                                                                 padding: "1%",
                                                                 width: "100%",
                                                              }
                                                            : { padding: "1%", width: "100%" }
                                                      }
                                                   >
                                                      <Paper elevation={4} disabled={readOnly ? true : false}>
                                                         <TerminalGroup
                                                            termGroupList={termGroupList}
                                                            terminals={props.terminals}
                                                            setSelectedTerminal={setSelectedTerminal}
                                                            selectedTerminal={selectedTerminal}
                                                         />
                                                      </Paper>
                                                   </div>
                                                )}
                                             </Grid>
                                          </Grid>
                                       </Box>
                                    )}
                                    {tabsValue === 1 && (
                                       <Box p={3}>
                                          <ActivityLogGrid
                                             columns={[
                                                {
                                                   field: "date",
                                                   headerName: "Date",
                                                   description: "Date",
                                                   type: "date",
                                                   flex: 0.1,
                                                },
                                                {
                                                   field: "hour",
                                                   headerName: "Hour",
                                                   description: "Hour",
                                                   type: "string",
                                                   flex: 0.1,
                                                },

                                                {
                                                   field: "event",
                                                   headerName: "Event",
                                                   description: "Event",
                                                   type: "string",
                                                   flex: 0.2,
                                                },
                                                {
                                                   field: "description",
                                                   headerName: "Event Description",
                                                   description: "Event Description",
                                                   type: "string",
                                                   flex: 0.6,
                                                },
                                             ]}
                                             tableData={activityTableData}
                                          />
                                       </Box>
                                    )}
                                 </Paper>
                              </Box>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
                  <ExtendRatesDialog
                     open={extendRatesModal}
                     handleCancel={() => setExtendRatesModal(!extendRatesModal)}
                     extendRatesFunc={extendRates}
                     title={`Extend Rates For ${selectedTerminal ? selectedTerminal.name : "Terminal"}`}
                     text={`You are about to extend all rates for all lanes for the CPG default tariff for ${
                        selectedTerminal ? selectedTerminal.name : "the terminal"
                     } ending on ${dateFormatter(extendDate)}. Do you wish to continue?`}
                  />
                  <ExpireRatesDialog
                     open={expireRatesModal}
                     handleCancel={() => setExpireRatesModal(!expireRatesModal)}
                     expireRatesFunc={expireRates}
                     title={`Expire Rates For ${selectedTerminal ? selectedTerminal.name : "Terminal"}`}
                     text={`You are about to expire all rates for all lanes for the CPG default tariff for ${
                        selectedTerminal ? selectedTerminal.name : "the terminal"
                     } ending on ${dateFormatter(expireDate)}. Do you wish to continue?`}
                  />
               </div>
            </div>
         )}
      </>
   );
};

function mapStateToProps(state, ownProps) {
   return {
      terminals: state.terminal.terminals || [],
      fuelTariffs: state.fuel.tariff || [],
      accessorialTariffs: state.accessorial.tariff || [],
      user: state.user.currentUser,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      getTerminals: () => dispatch(terminalActions.getTerminals()),
   };
}

TerminalDetails.propTypes = {
   match: PropTypes.shape({
      params: PropTypes.shape({
         id: PropTypes.string.isRequired,
      }),
   }),
   fuelTariffs: PropTypes.array.isRequired,
   accessorialTariffs: PropTypes.array.isRequired,
   terminals: PropTypes.array.isRequired,
   user: PropTypes.object.isRequired,
   getTerminals: PropTypes.func.isRequired,
};

TerminalDetails.defaultProps = {
   match: { params: { id: "" } },
   fuelTariffs: [],
   accessorialTariffs: [],
   terminals: [],
   user: {},
   getTerminals: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(TerminalDetails);
